import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SEO from '~/components/seo'
import ProductGrid from '~/components/ProductGrid'
import { CollectionDescription } from '../utils/styles'
import { Img } from '~/utils/styles'

const IndexPage = () => {
  const { shopifyCollection } = useStaticQuery(
    graphql`
      query {
        shopifyCollection(handle: { eq: "dave-pentin-artist" }) {
          id
          title
          handle
          description
          descriptionHtml
          image {
            src
            localFile {
              childImageSharp {
                fluid(maxWidth: 910) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <>
      <SEO
        title={shopifyCollection.title}
        keywords={[`gallery`, `artist`, `contemporary`]}
      />
      {/* <h2>About</h2> */}
      {shopifyCollection.image.localFile && (
        <Img
          fluid={shopifyCollection.image.localFile.childImageSharp.fluid}
          alt={shopifyCollection.handle}
        />
      )}

      <CollectionDescription
        dangerouslySetInnerHTML={{ __html: shopifyCollection.descriptionHtml }}
      />
      <ProductGrid />
    </>
  )
}

export default IndexPage
